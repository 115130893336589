
function BottomInfo() {

  return (
    <div className="w-full bg-gray-50 flex h-16 items-center justify-center whitespace-break-spaces">
            <h2>이 페이지의 모든 정보는 서울 열린데이터 광장에서 제공받습니다.
              문의 : tldudgotla@gmail.com</h2>
    </div>
  )

}

export default BottomInfo;